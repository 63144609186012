<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="assessments"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Опросы экспертной оценки</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.skillId="{ item }">
      <div class="ma-2">
        <div class="mb-1">
          {{ skillName(item) }}
        </div>

        <div>
          <VChip small>
            {{ item.skillId }}
          </VChip>
        </div>
      </div>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'AssessmentsTable',

  props: {
    assessments: {
      type: Array,
      required: true,
    },

    skills: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Описание',
          align: 'left',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Навык',
          align: 'left',
          sortable: true,
          value: 'skillId',
        },
        {
          text: 'Статус',
          align: 'left',
          sortable: true,
          value: 'status',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },

  methods: {
    skillName(item) {
      const skill = this.skills.find((c) => c.id === item.skillId);

      if (skill) return skill.name;

      return '';
    },
  },
};
</script>
