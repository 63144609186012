<template>
  <TTView>
    <VRow>
      <VCol>
        <AssessmentsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AssessmentsTableContainer from '../../components/expert-assessment/AssessmentsTableContainer.vue';

export default {
  name: 'AssessmentsList',

  components: {
    AssessmentsTableContainer,
  },
};
</script>
