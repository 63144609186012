<template>
  <AssessmentsTable
    :assessments="assessments"
    :skills="skills"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_EXPERT_ASSESSMENTS_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый опрос
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить опрос
          </VCardTitle>

          <VCardText>
            Опрос <span class="red--text">{{ assessment.name }}</span> будет удален! <br>
            Действительно хотите удалить опрос?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_EXPERT_ASSESSMENTS_VIEW,
              params : {
                assessmentId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_EXPERT_ASSESSMENTS_INDICATORS_INDEX,
              params : {
                assessmentId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-list
            </VIcon>
          </VBtn>
        </template>

        <span>Индикаторы</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_EXPERT_ASSESSMENTS_EDIT,
              params : {
                assessmentId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteAssessment(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </AssessmentsTable>
</template>

<script>
import AssessmentsTable from './AssessmentsTable.vue';

export default {
  name: 'AssessmentsTableContainer',

  components: {
    AssessmentsTable,
  },

  inject: ['Names'],

  data() {
    return {
      skills: [],
      assessments: [],
      loading: false,
      assessment: {},
      confirmDialog: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { skillAssessments } = await this.$di.api.ExpertAssessment.skillAssessmentIndex();
        const { skills } = await this.$di.api.CompetencyMatrix.SkillsIndex();

        this.assessments = skillAssessments;
        this.skills = skills;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.assessment;
        const data = { id };

        await this.$di.api.ExpertAssessment.skillAssessmentDelete({ data });

        this.resetAssessmentEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetAssessmentEdit() {
      this.confirmDialog = false;
      this.assessment = {};
    },

    handleCancel() {
      this.resetAssessmentEdit();
    },

    deleteAssessment(item) {
      this.confirmDialog = true;
      this.assessment = { ...item };
    },
  },
};
</script>
